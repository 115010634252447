import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper/core';
import PromotionBanner from '../PromotionBanner/PromotionBanner';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';

SwiperCore.use([Navigation, Autoplay]);

type Props = {
  promotions: any;
};

const Promotions: React.FC<Props> = ({ promotions }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper: SwiperCore) => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <>
      <div className="w-full">
        <Swiper
          slidesPerView={1}
          navigation={{
            disabledClass: 'swipper-navigation-disabled',
          }}
          autoplay={{ delay: 10000, disableOnInteraction: false }}
          onSlideChange={handleSlideChange}
        >
          {promotions.map((promotion, index) => (
            <SwiperSlide key={index}>
              <PromotionBanner
                promotion={promotion}
                isActive={index === activeIndex}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Promotions;
